import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

const App = () => {
  const [columns, setColumns] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [page, setPage] = useState(0); // State to manage current page
  const [pageSize, setPageSize] = useState(10); // State to manage rows per page

  useEffect(() => {
    // Set default columns (ID column)
    setColumns([{ field: "id", headerName: "ID", width: 90 }]);
    loadDefaultFile();
  }, []);

  const loadDefaultFile = async () => {
    try {
      const response = await fetch("/default.xlsx");
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
        type: "array",
      });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0];
      const rows = jsonData.slice(1).map((row, index) => {
        const rowData = { id: index + 1 }; // Add unique id to each row
        headers.forEach((header, i) => {
          rowData[header] = row[i];
        });
        return rowData;
      });

      setHeaders(headers);
      setColumns(
        headers.map((header) => ({
          field: header,
          headerName: header,
          sortable: true,
          width: 150,
        }))
      );

      setOriginalData(rows);
      setFilteredData(rows);

      // Reset search and dropdown
      setSelectedHeader("");
      setSearchTerm("");
      setPage(0); // Reset page to the first page
    } catch (error) {
      console.error("Error loading default file:", error);
    }
  };

  // Function to handle file upload
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
        type: "array",
      });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0];
      const rows = jsonData.slice(1).map((row, index) => {
        const rowData = { id: index + 1 }; // Add unique id to each row
        headers.forEach((header, i) => {
          rowData[header] = row[i];
        });
        return rowData;
      });

      setHeaders(headers);
      setColumns(
        headers.map((header) => ({
          field: header,
          headerName: header,
          sortable: true,
          width: 150,
        }))
      );

      setOriginalData(rows);
      setFilteredData(rows);

      // Reset search and dropdown
      setSelectedHeader("");
      setSearchTerm("");
      setPage(0); // Reset page to the first page
    };
    reader.readAsArrayBuffer(file);
  };

  // Function to handle search based on selected header and search term
  const handleSearch = () => {
    if (!selectedHeader || !searchTerm) {
      return;
    }
    const filteredData = originalData.filter((row) =>
      String(row[selectedHeader]).includes(searchTerm)
    );
    setFilteredData(filteredData);
    setShowTable(true);
    setPage(0); // Reset page to the first page when searching/filtering
  };

  // Function to reset filters and show original data
  const resetFilters = () => {
    setFilteredData(originalData);
    setSelectedHeader("");
    setSearchTerm("");
    setShowTable(false);
    setPage(0); // Reset page to the first page when resetting filters
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {/* <h2>Upload Excel File</h2>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={(e) => handleFileUpload(e.target.files[0])}
        style={{ marginBottom: "20px" }}
      /> */}
      <div>
        <select
          value={selectedHeader}
          onChange={(e) => setSelectedHeader(e.target.value)}
          style={{ marginRight: "10px" }}
        >
          <option value="">Select Header</option>
          {headers.map((header) => (
            <option key={header} value={header}>
              {header}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        <Button onClick={handleSearch}>Search</Button>
        {/* Render Reset button if either searchTerm or selectedHeader is truthy */}
        {(searchTerm || selectedHeader) && (
          <Button onClick={resetFilters} style={{ marginLeft: "10px" }}>
            Reset
          </Button>
        )}
      </div>
      {showTable ? (
        <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            onPageChange={(newPage) => setPage(newPage)}
            autoHeight
          />
        </div>
      ) : (
        <p>No data found</p>
      )}
    </div>
  );
};

export default App;
